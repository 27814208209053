const config = {
  siteTitle: "آرگو وی‌پی‌ان",
  siteTitleShort: "ArgoVPN", 
  siteTitleAlt: "آرگو وی‌پی‌ان - ArgoVPN",
  siteUrl: "https://argovpn.com",
  pathPrefix: "/",
  siteDescription: "آرگو وی‌پی‌ان - ArgoVPN",
  dateFromFormat: "YYYY-MM-DD",
  siteLogo: "/android-chrome-256x256.png",
  dateFormat: "DD/MM/YYYY",
  postsPerPage: 24,
  userName: "ArgoVPN",
  userEmail: "info@argovpn.com",
  userTwitter: "@ArgoVpn",
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
