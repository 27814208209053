import React, { Component } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "../layout/index.en";
import FaqAll from "../components/FAQ/FaqAll.en"
import config from "../../data/SiteConfig";
import DownloadAPP from "../components/DownloadAPP/DownloadAPP.en";


class FaqPage extends Component {
  render() {
    return (
      <Layout>
        <div className="faq-container">
          <HelmetProvider>
            <Helmet title={`FAQs | ArgoVPN`} />
          </HelmetProvider>
          <FaqAll />
        </div>
        <DownloadAPP />
      </Layout>
    );
  }
}

export default FaqPage;
