import React, { Component } from "react";
import Accordion from './../Accordion/Accordion';

import "./FAQ.css";
import "./FaqAll.css";

class FaqAll extends Component {
  render() {
    return (
      <div className="faq faqall en">
      <h2>FAQs section will be translated as soon</h2>
      {/* <Accordion>
        <div label='آیا اطلاعات بین دستگاه من و سرورهای آرگو وی‌پی‌ان (ArgoVPN) به‌صورت رمزنگاری شده ارسال می‌شود؟'>
          <p>بله، اطلاعات کاربر تا سرورهای ما به‌وسیله شیوه‌های AES-GCM-128 و یا ChaCha20-Poly1305 رمزنگاری می‌شوند. بهره‌گیری از پروتکل TLS برای فراهم آوردن Perfect Secrecy از دیگر راهبردهای امنیتی ArgoVPN است.</p>
        </div>
        <div label='کلید رمزنگاری اطلاعات من چگونه تعیین می‌شود؟'>
          <p>پروتکل Argo Authenticator در هر بار اتصال یک کلید کاملا تصادفی تولید کرده و به‌صورت ایمن به گوشی هوشمند شما انتقال می‌دهد.</p>
        </div>
        <div label='آیا کلید خصوصی رمزنگاری من توسط دولت‌ها و هکرها در هنگام ارسال از سرور به گوشی من قابل شنود و بازیابی است؟'>
          <p>خیر، پروتکل Argo Authenticator انتقال کاملا امن این کلید را تضمین می‌کند. به دلیل قرارگیری کلید عمومی رمزنگاری در کلاینت، امکان انجام حملات مرد میانی وجود ندارد.</p>
        </div>
        <div label='پروتکل Argo Authenticator از چه شیوه‌ای برای رمزنگاری استفاده می‌کند؟'>
          <p>پروتکل Argo Authenticator از ترکیب رمزنگاری AES-GCM-256 و RSA با استفاده از کلید 2048 بیت استفاده می‌کند.</p>
        </div>
        <div label='آیا استفاده از آرگو وی‌پی‌ان (ArgoVPN) در مصرف باتری گوشی من تاثیر منفی خواهد داشت؟'>
          <p>نصب هرگونه برنامه فیلترشکن به دلیل درگیر کردن پردازنده و استفاده از شبکه باعث افزایش مصرف باتری شما می‌شود. ما در طراحی ArgoVPN به این مساله اهمیت ویژه‌ای داده‌ایم و سعی کرده‌ایم با استفاده از تکنولوژی‌های جدید سیستم عامل اندروید از جمله WorkManager تا جای ممکن مصرف باتری را کاهش دهیم. قابلیت جداسازی برنامه‌ها از تونل وی‌پی‌ان نیز از جمله راهکارهای دیگر ما برای کاهش هر چه بیشتر مصرف باتری شماست.</p>
        </div>
        <div label='چرا به هنگام اتصال به آرگو وی‌پی‌ان (ArgoVPN) با خطای «تنظیمات بارگزاری نشده، لطفا صبر کنید» مواجه می‌شوم؟'>
          <p>ArgoVPN برای کارکرد صحیح نیازمند برخی تنظیمات است. لطفا تا دریافت تنظیمات منتظر بمانید. در صورتی‌که ArgoVPN به هر دلیلی نتواند تنظیمات خود را دریافت کند، از آخرین تنظیمات دریافت شده استفاده خواهد کرد.</p>
        </div>
        <div label='آیا راهی برای اطلاع از آخرین اخبار آرگو وی‌پی‌ان (ArgoVPN) وجود دارد؟'>
          <p>بله، شما می‌توانید با دنبال کردن ما در شبکه‌های اجتماعی (توییتر، تلگرام) و همچنین وبلاگ ArgoVPN از آخرین اخبار ArgoVPN مطلع شوید.</p>
        </div>
        <div label='اگر از آرگو وی‌پی‌ان (ArgoVPN) برای فعالیت علیه حکومت در شبکه‌های اجتماعی خارجی و داخلی استفاده کنم، آیا امکان ردگیری من وجود دارد؟'>
          <p>توجه داشته باشید که ArgoVPN تنها یک ابزار عبور از سد فیلترینگ اینترنت است که بر روی سرعت و امنیت کاربران متمرکز است؛ با این حال نباید از آن به عنوان یک ابزار پنهان کردن هویت برای برقراری ارتباطات اینترنتی و انجام وب‌گردی ناشناس استفاده کرد. از این رو، تمامی مسئولیت‌های امنیتی مرتبط با استفاده نادرست و نابجا از برنامه ArgoVPN بر عهده خود کاربران است. توجه داشته باشید که هیچ وی‌پی‌ان‌ای امکان ناشناس‌سازی کامل هویت شما را در اینترنت ندارد و چنانچه تمایل دارید تمام فعالیت‌های اینترنتی شما به صورت ناشناس و کاملا امن انجام شود، باید از مرورگر تور استفاده کنید.</p>
        </div>
        <div label='آیا می‌توانم یک دامنه ثبت کنم و با خانواده‌ام به اشتراک بگذارم تا آنها هم بتوانند از فیلترینگ عبور کنند؟'>
          <p>بله، شما می‌توانید آدرس فالکن خود را با دوستان و آشنایان خود به اشتراک بگذارید.</p>
        </div>
        <div label='آیا تبلیغات قبول می‌کنید؟'>
          <p>خیر، آرگو وی‌پی‌ان (ArgoVPN) در حال حاضر هیچگونه تبلیغاتی قبول نمی‌کند.</p>
        </div>
        <div label='آیا آرگو وی‌پی‌ان (ArgoVPN) محدودیت حجم و سرعت دارد؟'>
          <p>خیر، در حال حاضر هیچگونه محدودیتی از نظر حجم، سرعت و یا زمان برای کاربران ArgoVPN اعمال نشده است.</p>
        </div>
        <div label='آیا در صورت استفاده از فالکن امکان مسدود شدن دامنه من وجود دارد؟'>
          <p>مسدود شدن دامنه شما به عوامل زیادی بستگی دارد، به طور مثال اگر آدرس دامنه متصل به فالکن خود را به صورت عمومی در شبکه‌های اجتماعی منتشر کنید، دامنه شما در صورت مشاهده توسط اپراتورهای فیلترینگ و پلیس فتا فیلتر خواهد شد. تیم توسعه ArgoVPN می‌کوشد تا به‌طور پیوسته عواملی که منجر به بلاک شدن خودکار دامین‌های شما می‌شود را شناسایی و رفع کند، اما تضمینی برای فیلتر نشدن دامنه‌های شما نمی‌دهد، لذا ما به کاربران خود توصیه می‌کنیم که از دامنه‌های اصلی خود استفاده نکنند تا در صورت بروز مشکل با ضرر جدی مواجه نشوند.</p>
        </div>                                                                     
      </Accordion> */}
      </div>
    );
  }
}

export default FaqAll;
